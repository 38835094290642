// Auth
export const authAPI = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/administration/login'
export const refreshTokenUrl = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/administration/refresh-token'
export const forgotPasword = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/app/forgot-password/'
export const forgotPaswordConfirmation = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/app/forgot-password/'
export const authTokenAPI = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/administration/login'


//USED IN "mensajes"
export const getRecibedMsjAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/Feed/'
export const getSentMsjAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/MessagePrivate/sent'
export const getUserNamesAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/xperson_neighbourhood/neighbourhood'
export const postPrivateMsjAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/MessagePrivate'
export const postPublicMsjAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/MessagePrivate/public'
export const getUnreadMsjAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/MessagePrivate/received/unread'

//USED IN "familiares"
export const getFamiliesByPropietaryIdAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/masterdata/families/propietario/'

//USED IN "vehiculos"
export const getVehicleByPropietaryIdAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/vehicle/user/'

//USED IN "feed"
export const getEventListAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/Event/'
export const getNewsListAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/NewsArticle/'
export const postNewEventAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/Event/'
export const postNewArticleAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/NewsArticle/'
export const deleteArticleAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/NewsArticle/'
export const deleteEventAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/Event/'

export const editEventAPI = 'todo:api'
export const editNewsAPI = 'todo:api'

//USED IN "lotes"
export const getFunctionalUnitAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/masterdata/functional-units/neighbourhood'

//USED IN "padron"
export const postNewProprietaryAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person/propietary'
export const postImportMasiveProprietariesAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person/import-propietary'
export const getSearchedPadronAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/Propietario/search/'
export const getPadronAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person/searchPropietary'
export const editPadronAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person'

//USED IN "proveedores"
export const getCategoriesAPI = 'https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/categories'
export const getSuppliersAPI = 'https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/suppliers'
export const getServicesAPI = 'https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/services/categoryId'
export const getNeighbourhoodAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/masterdata/neighbourhoods'
export const postNewSupplier = 'https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/suppliers'
export const postNewCategory = 'https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/categories'
export const postNewService = 'https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/services/categoryId'
export const getSuppliersRequestAPI = 'https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/request'

//USED IN "reservas"
export const activitiesAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activities'
export const getSpacesAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/spaces/actividadBarrioId'
export const postNewSpaceAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/active_time'
export const postNewExceptionsAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/exception-spaces'
export const getBookingRequestAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/booking/future'
export const delSpaceAPI = `https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/Space`
export const deleteActivityAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activities'
export const deleteReservationAPI = 'todo-api-delete-reservation'
export const generateBookingAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/booking'
export const uploadTyCAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activity/uploadTyC'
export const deleteTyCAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activity/deleteTyC'

//USED IN "securityStaff"
export const postNewSecurityStaffAPI = 'https://q6b5px90od.execute-api.us-east-2.amazonaws.com/Staging/api/user/'
export const getSearchedSecurityStaffListAPI = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/security/user/search?name='
export const getSecurityStaffListAPI = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/security/user/search'

//USED IN "Accesos"
export const fetchLotsAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/masterdata/functional-units/neighbourhood'

//USED IN "invitados"
export const getInvitationsAPI = 'https://72o9de1ute.execute-api.us-east-2.amazonaws.com/Staging/api/event/neighbourhoodadmin/'
export const getGuestsAPI = 'https://72o9de1ute.execute-api.us-east-2.amazonaws.com/Staging/api/guest_frequency/username/'
export const getFilteredGuestsAPI = 'https://72o9de1ute.execute-api.us-east-2.amazonaws.com/Staging/api/guests/today-invites/search'

//USED IN "neighbourhood"
export const getDefaultActivities = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activity'
export const getDefaultCategories = ''

// USED IN "ActiveTimes"
export const activeTimesAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/active_time/space'

// USED IN "Billetera"
export const getAccountWithAliasAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/bank-account/get-account?alias='
export const getAccountWithUniqueKeyAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/bank-account/get-account?uniqueKey='
export const getAccountWithLoteAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/bank-account/get-account?propertyCode='
export const postRequestedTransactionAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/requested-transaction?'
export const getCodeQRImageAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/requested-transaction/generate-qr/'
export const getRegenerateOpenQR = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/requested-transaction/regenerate-openqr/'
export const getTransactionUserAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transaction/neighbourhood/'
export const getBalanceUserAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transaction/neighbourhood/balance'
export const postCreateTransactionRequestAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transfer/create-transfer-request'
export const getDebitAccountInfo = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/bank-account/get-neighbourhood-account'
export const getTransferPendingAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transfer/pendings-auth'
export const TransactionChargeWithCardAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transaction/pay-transaction'
export const getTransactionDataAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transaction/'
export const getNumberOfTransferPendingAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transfer/total-pendings-auth'
export const getApproveTransferAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transfer/approve-transfer-workflow'
export const getApproveTransferWithTokenAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transfer/approve-transfer-workflow?Token='
export const getDeniedTransferAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transfer/reject-transfer-workflow'
export const postReSendToken = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/token/sms/send'
export const getAccountQR = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/requested-transaction/search-person'

// USED IN "Expensas"
export const postGenerateExpensasAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/expense/generate-manual-expense'
export const postGenerateAutomaticExpensasAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/expense/generate-automatic-expense'
export const getHomeExpensas = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/expense/search?'
export const getExpensasPorCliente = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/expense/client?ClientId='
export const getFileDetailClient = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/expense/export/detail/'
export const getFileDetailNeighbourhood = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/expense/export/overview?NeighborhoodGuid='
export const postUploadFile = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/expense/process-expense-result-manual'
export const postExecuteExpensesReportAPI =
  'https://0xtih6e5g9.execute-api.us-east-2.amazonaws.com/Staging' // STAGING
// 'https://9c2jbeu320.execute-api.us-east-2.amazonaws.com/prod' // PRODUCTION

// USED IN "Profile"
export const getRoles = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/role'
export const getMenus = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/menu'
export const getPersonByNeighbourhood = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person/neighbourhood'
export const fetchRefreshMenusForRoleAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person/menus'

// USED IN "Accesos"
export const fetchGetGuestAPI = 'https://72o9de1ute.execute-api.us-east-2.amazonaws.com/Staging/api/invites/' //(Get Guest)
export const fetchGetVehicleBrandsAPI = 'https://72o9de1ute.execute-api.us-east-2.amazonaws.com/Staging/api/vehicle_brand'
export const fetchGetVehicleModelsAPI = 'https://72o9de1ute.execute-api.us-east-2.amazonaws.com/Staging/api/vehicle_model/'
export const fetchNotificationsAPI = 'http://localhost/1'
export const fetchMensajeriaAPI = 'http://localhost/2'
export const fetchRegistrosAPI = 'https://72o9de1ute.execute-api.us-east-2.amazonaws.com/Staging/api/invites/states'

// USED IN "Tickets"
export const getCategoriesByRoleAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/category/getcategoriesbyReview'
export const getTicketsByCategoryAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/tickets/getticketsbycategory/'
export const getTicketMessagesAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/ticketmessage/getmsg/'
export const getSendMessageAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/ticketmessage/sendmsg/'
export const getAssignTicketsAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/ticketmessage/assignticket/'
export const getCloseTicketsAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/tickets/closeTicket/'
export const getPendingTicketsAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/tickets/changestate/'
export const TransferTicketsAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/tickets/changecategory/'
export const getSearchedTicketsAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/tickets/searchtickets/'
export const getTypesByCategoryAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/type/'
export const getAllCategoriesTickets = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/category/'

// USED IN "Users"
export const fetchNewUserAPI = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/administration/register/'
export const fetchEditUserAPI = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/administration/update/'
export const deleteUsuarioAPI = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/administration/deleteuser/'
export const fetchUsuariosByNeighbourhoodAPI = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/administration/getadminusersbyneighbourhood/'
export const getUsersAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person/searchPropietary'

// USED IN "Clasificados"
export const fetchProductsAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/shopItem/search-admin'
export const fetchDetailProductAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/shopItem/'
export const fetchDeleteProductAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/shopItem/state/inactive'
export const fetchAddProductAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/shopItem'
export const fetchEditProductAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/shopItem'
export const fetchVentasAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/order/sold'
export const fetchDetailVentaAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/ShopItem/'
export const fetchOrderCodigoQRAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/order/delivery-confirm-qr/'
export const fetchOrderQRAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/order/delivery-label/'
export const fetchDespachosAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/order/in-delivery-process'
export const fetchGetReadyToDeliverAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/order/ready-to-deliver/'
export const fetchUploadProductsFileAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/shopItem/import'
export const fetchConversationsAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/conversation/getconversations/all'
export const sendMsgConversationAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/message/sendmsg'
export const getMsgConversationAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/message/getmsg/'
export const newConversationAPI = 'https://fn4dff81p1.execute-api.us-east-2.amazonaws.com/Staging/api/conversation/startconversation/shops'
export const disclaimerMetodoPago = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/sys_param?code=MedioPagoTCLeyenda'

// hay que pedir a diseño el tema de categorias padres e hijos
// por ahora solamente vamos a dar de alta productos asi que hardcodeamos la categoria 1
export const fetchCategoriesAPIPadre = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/ShopItemCategory/parents'
export const fetchCategoriesAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/ShopItemCategory/children/1'
export const fetchItemConditionsAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/ShopItemCondition/0/20'
export const fetchShippingMethodsAPI = 'https://hg9ere3gji.execute-api.us-east-2.amazonaws.com/Staging/api/DeliveryMethod/0/20'
export const fetchPaymentMethodsAPI = 'https://k8cthq5jyj.execute-api.us-east-2.amazonaws.com/Staging/api/transaction-payment-method/shop'


// Reportes
export const fetchReporteMascotasAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/pet/report'
export const fetchReporteTicketsAPI = 'https://qqh1scxyd6.execute-api.us-east-2.amazonaws.com/Staging/api/tickets/report'
export const fetchReporteReservasAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/booking/report'


// Fundacion
//export const fetchFundacionUsersAPI = 'https://k09otvnln2.execute-api.us-east-2.amazonaws.com/Staging/api/authentication/administration/getusersbyneighbourhoodandappid'
export const fetchFundacionUsersAPI = 'https://7l29jjt214.execute-api.us-east-2.amazonaws.com/Staging/api/person/admin/getusersbyneighbourhoodandapplication'
export const storiesAPI='https://ujn810pdnk.execute-api.us-east-2.amazonaws.com/Staging/api/foundation/stories';
export const moviesAPI='https://ujn810pdnk.execute-api.us-east-2.amazonaws.com/Staging/api/foundation/movies';
export const moviesGetPresignedUrlAPI='https://ujn810pdnk.execute-api.us-east-2.amazonaws.com/Staging/api/foundation/movies/get-presigned-url';
export const storiesGetPresignedUrlAPI='https://ujn810pdnk.execute-api.us-east-2.amazonaws.com/Staging/api/foundation/stories/get-presigned-url';

export const donationsAPI='https://ujn810pdnk.execute-api.us-east-2.amazonaws.com/Staging/api/foundation/donation';
export const newsAPI = 'https://ujn810pdnk.execute-api.us-east-2.amazonaws.com/Staging/api/foundation/news'

// Gestor de consultas landing puertos
export const fetchVendorConsultasAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/vendor/ticket/search'
export const postVendorChangeStateAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/Vendor/ticket/change-state'
export const postVendorAssingUserAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/Vendor/ticket/assign-user'

export const getTicketAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/Vendor/Ticket/'
export const postTicketHistoryAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/vendor/ticket/save-history'
export const postTicketAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/vendor/ticket'
export const fetchVendorConsultasEstadosAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/vendorTicketState'
export const fetchEntrepreneurAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/Entrepreneurship'
export const fetchUsersGestorAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/User/filter'
export const fetchProductTypeAPI = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging/api/ProductType'
